<template>
	<div id="InvitedRecord">
		<div class="center">
			<div class="" style="margin: 18px 0;">
				<searchHeader :model="inputs"  ref="searchHeader" @search="searList"></searchHeader>
			</div>
			<!-- 邀请记录 -->
			<vxe-table class="mytable-scrollbar" stripe highlight-hover-row size="small" :border="false" ref="xTable2" id="toolbar_demo3" :data="tabList">
				<vxe-column type="seq" align="center" width="60" title="序号"></vxe-column>
				<vxe-column title="用户手机号" min-width="160">
					<template #default="{row}">
						<div>{{row.invite_contact_mobile||'--'}}</div>
					</template>
				</vxe-column>
				<vxe-column field="title" title="状态" min-width="140">
					<template #default="{ row }" min-width="160">
						<el-tag size="small" v-if="row.status == 1" type="warning">待激活</el-tag>
						<el-tag size="small" v-if="row.status == 2">已激活</el-tag>
						<el-tag size="small" v-if="row.status == 3" type="success">已提交</el-tag>
					</template>
				</vxe-column>
				<vxe-column title="邀请方式" width="120" >	
					<template #default="{ row }" min-width="160">
						<span v-if="row.invite_method == 1">定向邀请</span>
						<span v-if="row.invite_method == 2">公开邀请</span>
					</template>
				</vxe-column>
				<vxe-column field="invite_link" title="邀请链接" min-width="420" >
					<template #default="{row}">
						<el-popover
						    placement="top"
						    width="140"
						    trigger="click">
							<div class="invited-code-img-box">
								<img :src="row.code_url" >
							</div>
							<span slot="reference" class="hover-color" @click="popoverBtn(row)">{{row.invite_link}}</span>
						</el-popover>
					</template>
					
				</vxe-column>
				<vxe-column field="join_date" min-width="200">
					<template #header>
						<div class="">邀请人</div>
						<div class="">邀请时间</div>
					</template>
					<template #default="{ row }">
						<div class="">
							<div id="">{{ row.created_by||'--' }}</div>
							<div id="">{{ row.created_at||'--' }}</div>
						</div>
					</template>
				</vxe-column>
				<vxe-column title="操作" width="150" fixed="right">
					<template #default="{row,rowIndex}">
						<div class="active-box">
							<span class="empha" @click="goDtl(row)">记录</span>
							<span class="empha"  :class="['copy-text'+rowIndex]" :data-clipboard-text="row.invite_link" @click="copyBtn(row,rowIndex)" >复制链接</span>
						</div>
					</template>
				</vxe-column>
			</vxe-table>
			<pageNumber @changePage="changePage" :pages="pages" />
		</div>
	</div>
	
</template>

<script>
// import InvitedTab from './component/InvitedTab.vue'
	import Clipboard from 'clipboard'
	import pageNumber from "@/components/phmAll/pageNumber.vue";
	import textOver from '@/components/phmAll/textOver'
	import searchHeader from '@/components/phmAll/searchHeader'
	export default {
		components: {
			// InvitedTab,
			pageNumber,
			textOver,
			searchHeader,
		},
		data() {
			return {
				headers: {
					title: '邀请管理',
				},
				inputs: [
					//搜索输入框
					{
						type: 'input',
						field: 'invite_contact_mobile',
						label: '用户手机号',
						value: ''
					},
					{
						// 1全部 ;1待激活；2已激活；3已提交
						type: 'select',
						field: 'status',
						label: '状态',
						multiple: 'false',
						value: [],
						options: [
							{
								name: '待激活',
								value: 1
							},
							{
								name: '已激活',
								value: 2
							},
							{
								name: '已提交',
								value: 3
							}
						]
					},
					{
						// 1平台添加；2平台邀请；3租户邀请；4自主注册；5用户邀请
						type: 'select',
						field: 'invite_method',
						label: '邀请方式',
						multiple: 'false',
						value: [],
						options: [
							{
								name: '定向',
								value: 1
							},
							{
								name: '公开',
								value: 2
							}
						]
					},
					{
						type: 'input',
						field: 'created_by',
						label: '邀请人',
						value: ''
					},
					{
						type: 'time',
						field: 'created_at',
						label: '邀请时间',
						value: ''
					}
				],
				tabList: [],
				tableData:[],
				pages: {
					pageNum: 1,
					pageSize: 10,
					total: 10
				},
				searchObj: {},
			}
		},
		created() {
			this.$store.commit("updatePageNav", [
			  { icon: "fa fa-cog", name: "用户管理" },
			  { name: "邀请管理" },
			]);
			this.getTabList();
		},
		methods: {
			searList(info) {
				let sear = JSON.stringify(info) == '{}';
				if (!sear) {
					if (info.invite_type) {
						info.invite_type = Number(info.invite_type);
					}
					if (info.invite_contact_mobile) {
						info.invite_contact_mobile = Number(info.invite_contact_mobile);
					}
					if (info.created_user_mobile) {
						info.created_user_mobile = Number(info.created_user_mobile);
					}
					this.searchObj = JSON.parse(JSON.stringify(info));
				} else {
					this.searchObj = {};
				}
				this.getTabList(this.searchObj);
			},
			popoverBtn(row){
				if(row.code_url){
					return 1
				}
				let data = {
					content:row.invite_link,
					img_size:100,
				}
				this.$utils.getQrcode(data).then(res=>{
					row.code_url = res.img;
				}).catch(err=>{
					console.log('获取失败',err);
				})
			},
			
			changePage(val) {
				console.log('val==>', val);
				if (val.type == 'size') {
					this.pages.pageSize = val.num;
				} else {
					this.pages.pageNum = val.num;
				}
				let count = Object.keys(this.searchObj).length;
				if (count) {
					this.getTabList(this.searchObj);
				} else {
					this.getTabList();
				}
			},
			goDtl(row){
				this.$router.push({
					path:'/mySelf/invitedUser/invitedDetail',
					query:{
						code:row.serial_number,
						type:row.invite_method,
					}
				})
			},
			copyBtn(row,index){
				var clipboard = new Clipboard('.copy-text'+index);
				let _this = this;
				clipboard.on('success', e => {
					_this.$message.success('复制成功');
				})
				clipboard.on('error', e =>{
					_this.$message.error('该浏览器不支持复制');
				})
				this.clipboard = clipboard;
				setTimeout(()=>{
					this.clipboard.destroy &&this.clipboard.destroy()
				},500)
			},
			
			getTabList(info) {
				let obj = {};
				obj.page_size = this.pages.pageSize;
				obj.page = this.pages.pageNum;
				obj.invite_place = 1;
				obj.invite_type = 3;
				if (info) {
					for ( let key in info) {
						obj[key] = info[key]
					}
				} 
				this.$axios.postJSON(`/base/invite/common/list`, obj).then( res => {
					if (res.code == 200) {
						if (res.data && res.data.list) {
							res.data.list.map(item=>{
								item.code_url = "";
							})
							this.tabList = res.data.list;
							this.pages.pageNum = res.data.current_page;
							this.pages.total = res.data.total_count;
						} else {
							this.tabList = [];
							this.pages.total = 0;
							this.pages.pageNum = 1;
						}
						console.log('this.tabList==>', this.tabList);
					}
				}).catch( err => {
					console.log('err==>', err);
					return this.$message.error(err.msg)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
#InvitedRecord{
	width:1200px;
	margin:0 auto;
	background-color: #FFFFFF;
	padding: 20px;
	box-sizing: border-box;
	.active-box{
		.empha{
			color: #F56161;
			cursor: pointer;
			+.empha{
				margin-left:10px;
			}
		}
	}
}
</style>
