var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"InvitedRecord"}},[_c('div',{staticClass:"center"},[_c('div',{staticStyle:{"margin":"18px 0"}},[_c('searchHeader',{ref:"searchHeader",attrs:{"model":_vm.inputs},on:{"search":_vm.searList}})],1),_c('vxe-table',{ref:"xTable2",staticClass:"mytable-scrollbar",attrs:{"stripe":"","highlight-hover-row":"","size":"small","border":false,"id":"toolbar_demo3","data":_vm.tabList}},[_c('vxe-column',{attrs:{"type":"seq","align":"center","width":"60","title":"序号"}}),_c('vxe-column',{attrs:{"title":"用户手机号","min-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.invite_contact_mobile||'--'))])]}}])}),_c('vxe-column',{attrs:{"field":"title","title":"状态","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 1)?_c('el-tag',{attrs:{"size":"small","type":"warning"}},[_vm._v("待激活")]):_vm._e(),(row.status == 2)?_c('el-tag',{attrs:{"size":"small"}},[_vm._v("已激活")]):_vm._e(),(row.status == 3)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v("已提交")]):_vm._e()]}}])}),_c('vxe-column',{attrs:{"title":"邀请方式","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.invite_method == 1)?_c('span',[_vm._v("定向邀请")]):_vm._e(),(row.invite_method == 2)?_c('span',[_vm._v("公开邀请")]):_vm._e()]}}])}),_c('vxe-column',{attrs:{"field":"invite_link","title":"邀请链接","min-width":"420"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{attrs:{"placement":"top","width":"140","trigger":"click"}},[_c('div',{staticClass:"invited-code-img-box"},[_c('img',{attrs:{"src":row.code_url}})]),_c('span',{staticClass:"hover-color",attrs:{"slot":"reference"},on:{"click":function($event){return _vm.popoverBtn(row)}},slot:"reference"},[_vm._v(_vm._s(row.invite_link))])])]}}])}),_c('vxe-column',{attrs:{"field":"join_date","min-width":"200"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{},[_vm._v("邀请人")]),_c('div',{},[_vm._v("邀请时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{},[_c('div',{attrs:{"id":""}},[_vm._v(_vm._s(row.created_by||'--'))]),_c('div',{attrs:{"id":""}},[_vm._v(_vm._s(row.created_at||'--'))])])]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"active-box"},[_c('span',{staticClass:"empha",on:{"click":function($event){return _vm.goDtl(row)}}},[_vm._v("记录")]),_c('span',{staticClass:"empha",class:['copy-text'+rowIndex],attrs:{"data-clipboard-text":row.invite_link},on:{"click":function($event){return _vm.copyBtn(row,rowIndex)}}},[_vm._v("复制链接")])])]}}])})],1),_c('pageNumber',{attrs:{"pages":_vm.pages},on:{"changePage":_vm.changePage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }